@import "minima";

/* für Buildunterschriften
https://stackoverflow.com/questions/19331362/using-an-image-caption-in-markdown-jekyll*/
img + em { 
    display: block; 
}

img {
    margin: 1em;
}

ul.tags li {
    list-style-type: none;
    font-size: 80%;
}

.page-content .wrapper {
    display: flex;
    flex-flow: row;
    /* This aligns items to the end line on main-axis */
    justify-content: flex-end;
}

/* Medium screens */
@media all and (max-width: 800px) {
    .page-content .wrapper {
      /* When on medium sized screens, we center it by evenly distributing empty space around items */
      flex-flow: row wrap;
    justify-content: space-around;
    }
  }
  
  /* Small screens */
  @media all and (max-width: 500px) {
    .page-content .wrapper {
      flex-direction: column;
    }
  }